import React from 'react'
import sucessImg from '../../images/success.png'
import { Container } from '../../style'

type OpenAccountFormProps = {
  className?: string;
};

const Sent = ({
  className,
}: OpenAccountFormProps) => {
  return (
    <Container
      className={`d-flex position-relative align-items-center justify-content-center ${className}`}
    >
      <div className='col-12 col-md-8 col-lg-12 text-center'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-12 mt-2 mt-lg-4'>
            <div className='col-12 mx-auto'>
              <img
                src={sucessImg}
                alt='Baixe o App Inter'
              />
            </div>
            <p className='fs-24 lh-30 fw-600 text-grayscale--500  mt-3 mb-0'>
              Formulário enviado com sucesso!
            </p>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Sent
