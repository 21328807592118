import React, { MouseEvent } from 'react'

import QRCode from 'react-qr-code'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import sucesso from '../../images/sucesso.png'

import { Container } from '../../style'

type OpenAccountFormProps = {
  mobileLink?: string;
  qrBaixeApp: string;
  className?: string;
};

const UnderAge = ({
  mobileLink,
  qrBaixeApp,
  className,
}: OpenAccountFormProps) => {
  const WIDTH_MD = 768
  const windowWidth = useWidth(200)
  const [ sendDatalayerEvents ] = useDataLayer()
  const urlMobile = 'https://intergo.app/7820e737'
  const urlDesktop = '/pra-voce/conta-digital/conta-kids/'

  const asUnderAge = (evt: MouseEvent<HTMLSpanElement>) => {
    evt.preventDefault()
    if (windowWidth < WIDTH_MD) {
      window.location.href = urlMobile
    } else {
      window.location.href = urlDesktop
    }
  }

  return (
    <Container className={`d-flex position-relative align-items-center justify-content-center ${className}`}>
      <div className='col-12 col-lg-9 col-xl-10 mx-auto text-center text-lg-left'>
        <div className='row align-items-center justify-content-center justify-content-lg-between'>
          <div className='col-12 pb-md-4 d-md-flex justify-content-md-center mt-lg-3'>
            <img
              className='d-none d-md-block d-lg-none'
              src={sucesso}
              alt='Sucesso'
              width={204}
            />
            {mobileLink ? (
              <QRCode size={268} value={mobileLink} />
                ) : (
                  <img
                    className='d-none d-lg-block'
                    src={qrBaixeApp}
                    alt='Baixe o App Inter'
                    width={286}
                  />
                )}
          </div>
          <div className='col-12 col-md-8 col-lg-12 mt-4 mb-2 my-md-0'>
            <p className='fs-16 lh-20 fw-600 text-grayscale--400 mb-3'>
              Vimos que você tem menos de 18 anos.
            </p>
            <p className='fs-20 lh-24 fs-md-24 lh-md-28 fw-600 text-grayscale--500 mb-0'>
              Conheça nossa
              <span
                className='text-orange--extra cursor-pointer'
                title='Conhecer Conta Kids'
                onClick={(evt: MouseEvent<HTMLSpanElement>) => {
                  asUnderAge(evt)
                  sendDatalayerEvents({
                    section: 'dobra_1',
                    section_name: 'Empréstimo Consignado Inter',
                    element_action: 'submit',
                    element_name: 'Conta Kids',
                    redirect_url: windowWidth < WIDTH_MD ? urlMobile : urlDesktop,
                  })
                }}
              > Conta Kids
              </span>
              , que também é completa, digital e gratuita.
            </p>
          </div>
        </div>
        <p className='fs-14 lh-17 text-grayscale--400 mt-3 d-none d-lg-block border-lg-top pt-lg-4'>
          Abra a câmera do seu celular e aponte para o QR Code acima. É só
          baixar o app do Inter e clicar em “Abrir conta completa e gratuita”. É rápido e fácil!
        </p>
        <p className='fs-14 lh-17 text-grayscale--400 mt-3 d-none d-lg-block mb-lg-4'>
          De acordo com nossa Política de Privacidade, não armazenamos dados
          de menores de 18 anos sem o consentimento dos responsáveis.
        </p>
        <div className='col-12 col-md-4 my-2 my-md-0 px-lg-0 d-md-none'>
          {mobileLink ? (
            <QRCode size={268} value={mobileLink} />
              ) : (
                <img src={sucesso} alt='Sucesso' />
              )}
        </div>
      </div>
    </Container>
  )
}

export default UnderAge
