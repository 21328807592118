export enum FormFields {
  name = 'name',
  cpf = 'cpf',
  celular = 'celular',
  email = 'email',
  convenio = 'convenio',
  matricula = 'matricula',
  dataNascimento = 'dataNascimento',
  product = 'product',
  valor = 'valor',
}

export interface IFormValues {
  name: FormFields.name;
  cpf: FormFields.cpf;
  celular: FormFields.celular;
  email: FormFields.email;
  convenio: FormFields.convenio;
  matricula: FormFields.matricula;
  dataNascimento: FormFields.dataNascimento;
  product: FormFields.product;
  valor: FormFields.valor;
}

export type UtmStateType = string | string[] | null | undefined

export type SelectOptionsType = {
  name: string;
  value: string;
}
