import { orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: ${orange.extra};
  min-height: 100vh;
`

export const HeaderLogo = styled.section`
  background-color: ${white};
  height: 115px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
