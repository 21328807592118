import React, { useState, useEffect, MouseEvent, ChangeEvent } from 'react'
import { CONTACT_FORM_CONSIGNADO_PARCEIRO } from 'src/config/api/Urls'

import axios from 'axios'
import { useForm, UseFormMethods } from 'react-hook-form'
import { differenceInYears } from 'date-fns'

import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'

import { getParameterByName, sendDataLayerEvent } from 'src/shared/helpers'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import QRCodeBaixeOApp from 'src/assets/images/qrcode-header.jpg'

import { Container, Checkbox } from './style'
import { IFormValues, UtmStateType, SelectOptionsType, FormFields } from './types'

import ProductsData from './assets/data/products.json'
import ConvenioData from './assets/data/convenios.json'

import {
  Error,
  Sent,
  UnderAge,
} from './status/_index'

const ParceiroConsignadoForm = () => {
  const products: SelectOptionsType[] = ProductsData
  const convenios: SelectOptionsType[] = ConvenioData
  const [ inputValue, setInputValue ] = useState(0)
  const urlPrivacidade = 'https://inter.co/politica-de-privacidade/privacidade/'

  const {
    register,
    errors,
    handleSubmit,
    setValue,
  }: UseFormMethods<IFormValues> = useForm<IFormValues>()

  const formStatus = {
    idle: 'idle',
    loading: 'loading',
    sent: 'sent',
    error: 'error',
    underAge: 'underAge',
  }

  const [ product, setProduct ] = useState('')
  const [ convenio, setConvenio ] = useState('')
  const [ accept, setAccept ] = useState(false)
  const [ status, setStatus ] = useState(formStatus.idle)
  const [ utmSource, setUtmSource ] = useState<UtmStateType>(null)
  const [ sendDatalayerEvents ] = useDataLayer()

  const hasOptionSelected = (valueSelect: string) => {
    return valueSelect ? 'ativo' : ''
  }

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', '')?.toString())
  }, [])

  function currencyToFloat (source: string, currencyNumber: string) {
    const result =
      currencyNumber === ''
        ? 0
        : parseInt(currencyNumber.replace(/\D/g, '')) / 100
    return source === 'range' ? result * 100 : result
  }

  function floatToCurrency (floatNumber: number) {
    return floatNumber.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  function changeInput (evt: ChangeEvent<HTMLInputElement>) {
    const inputName = evt.target.name
    const inputValue = evt.target.value

    const insertValue = currencyToFloat(inputName, inputValue)
    setInputValue(insertValue)
  }

  const sendForm = async (data: IFormValues) => {
    setStatus(formStatus.loading)

    const newDate = data.dataNascimento.replace(/\D/g, ',').split(',').reverse().join('-')
    const result = differenceInYears(new Date(), new Date(newDate))
    const cpfFormatted = data.cpf.replace(/[^\w\s]/gi, '').replace(' ', '')
    const nameAndSurname = data.name.match(/^(\S+)\s(.*)/)
    const slicedName = nameAndSurname && nameAndSurname.slice(1)
    const valorFinal = currencyToFloat('', data.valor.replace('R$ ', ''))

    const arrayUtmSource = [
      'confianza_field',
      'confianza',
      'adelfi_field',
      'adelfi',
    ]

    const formData = {
      Autarquia: 'N/A',
      CPF: cpfFormatted,
      Convenio: data.convenio,
      DataNascimento: newDate,
      Email: data.email,
      Matricula: data.matricula,
      PrimeiroNome: slicedName && slicedName[0],
      Produto: data.product,
      Telefone: data.celular,
      UltimoNome: slicedName && slicedName[1],
      UTMSource: utmSource,
      Valor: +valorFinal,
      LeadSource: arrayUtmSource?.includes(`${utmSource}`) ? 'FieldCapture' : 'Site',
    }

    try {
      if (result < 18) {
        setStatus(formStatus.underAge)
        sendDatalayerEvents({
          section: 'dobra_1',
          section_name: 'Empréstimo Consignado Inter',
          element_action: 'submit',
          element_name: 'Receber Proposta',
        })
      } else {
        await axios.post(`${CONTACT_FORM_CONSIGNADO_PARCEIRO}`, [ formData ])

        sendDataLayerEvent('form_submitted')
        sendDatalayerEvents({
          section: 'dobra_1',
          section_name: 'Empréstimo Consignado Inter',
          element_action: 'submit',
          element_name: 'Receber Proposta',
          step: 'success',
        })

        setStatus(formStatus.sent)
      }
    } catch (e) {
      sendDatalayerEvents({
        section: 'dobra_1',
        section_name: 'Empréstimo Consignado Inter',
        element_action: 'submit',
        element_name: 'Receber Proposta',
      })
      setStatus(formStatus.error)
    }
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setStatus(formStatus.idle)
  }

  return (
    <>
      {status === formStatus.error && (
        <Error handleReturn={handleReturn} />
      )}
      {status === formStatus.sent && (
        <Sent />
      )}
      {status === formStatus.underAge && (
        <UnderAge qrBaixeApp={QRCodeBaixeOApp} />
      )}
      <Container
        className={`${
          status === formStatus.idle || status === formStatus.loading
            ? 'd-flex'
            : 'd-none'
        } align-items-center`}
      >
        <div className='row mx-0'>
          <div className='col-12'>
            <form
              className='form--default'
              name='open_account'
              onSubmit={handleSubmit(sendForm)}
            >
              <div className='row mt-3'>
                <div
                  className={`col-12 pb-3 px-0 px-md-3 ${errors[FormFields.name] &&
                    'input-error'}`}
                >
                  <label htmlFor={FormFields.name} className='fs-14 lh-18 d-block'>
                    Nome
                  </label>
                  <input
                    className='w-100'
                    ref={register({
                      required: 'Por favor, digite seu nome completo',
                      validate: {
                        isName: (value: string) =>
                          Validations.name(value) ||
                          'Por favor, digite seu nome completo',
                      },
                    })}
                    name={FormFields.name}
                    id={FormFields.name}
                    type='text'
                    maxLength={100}
                    placeholder='Digite seu nome completo'
                  />
                  {errors[FormFields.name] && (
                    <p className='fs-12 text-right mb-0'>
                      {errors[FormFields.name].message}
                    </p>
                  )}
                </div>
                <div
                  className={`col-12 pb-3 px-0 px-md-3 ${errors[FormFields.cpf] &&
                    'input-error'}`}
                >
                  <label htmlFor={FormFields.cpf} className='fs-14 lh-18 d-block'>
                    CPF
                  </label>
                  <input
                    className='w-100'
                    ref={register({
                      required: 'Digite seu CPF, apenas números',
                      validate: {
                        isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                      },
                    })}
                    name={FormFields.cpf}
                    id={FormFields.cpf}
                    type='text'
                    maxLength={100}
                    placeholder='Digite seu CPF, apenas números'
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(FormFields.cpf, Masks.MaskCPF(event.currentTarget.value))}
                  />
                  {errors[FormFields.cpf] && (
                    <p className='fs-12 text-right mb-0'>
                      {errors[FormFields.cpf].message}
                    </p>
                  )}
                </div>
                <div
                  className={`col-12 pb-3 px-0 px-md-3 ${errors[FormFields.celular] &&
                    'input-error'}`}
                >
                  <label htmlFor={FormFields.celular} className='fs-14 lh-18 d-block'>
                    Celular
                  </label>
                  <input
                    className='w-100'
                    ref={register({
                      required: 'Digite um número de celular válido',
                      pattern: {
                        value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                        message: 'Telefone inválido',
                      },
                    })}
                    name={FormFields.celular}
                    id={FormFields.celular}
                    type='tel'
                    placeholder='(XX) XXXXX-XXXX'
                    maxLength={15}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setValue(
                        FormFields.celular,
                        Masks.MaskPHONE(event.currentTarget.value),
                    )}
                  />
                  {errors[FormFields.celular] && (
                    <p className='fs-12 text-right mb-0'>
                      {errors[FormFields.celular].message}
                    </p>
                  )}
                </div>
                <div
                  className={`col-12 pb-3 px-0 px-md-3 ${errors[FormFields.email] &&
                    'input-error'}`}
                >
                  <label htmlFor={FormFields.email} className='fs-14 lh-18 d-block'>
                    E-mail
                  </label>
                  <input
                    className='w-100'
                    ref={register({
                      required: 'Digite um e-mail válido',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'E-mail inválido',
                      },
                    })}
                    name={FormFields.email}
                    id={FormFields.email}
                    type='text'
                    placeholder='Digite seu e-mail'
                  />
                  {errors[FormFields.email] && (
                    <p className='fs-12 text-right mb-0'>
                      {errors[FormFields.email].message}
                    </p>
                  )}
                </div>
                <div className={`col-12 pb-3 px-0 px-md-3 ${errors[FormFields.convenio] && 'input-error'}`}>
                  <label htmlFor={FormFields.convenio} className='fs-14 lh-18 d-block text-grayscale--100'>Convênio</label>
                  <span className='select'>
                    <select
                      className={hasOptionSelected(convenio)}
                      name={FormFields.convenio}
                      id={FormFields.convenio}
                      ref={register({
                        required: 'Selecione o convenio',
                      })}
                      onChange={(event: ChangeEvent<HTMLSelectElement>) => setConvenio(event.currentTarget.value)}
                    >
                      <option disabled selected value=''>Selecione seu convênio</option>
                      {convenios.map((item: SelectOptionsType) => (
                        <option value={item.value} key={item.name}>{item.name}</option>
                      ),
                    )}
                    </select>
                  </span>
                  {errors[FormFields.convenio] && <p className='fs-12 text-right mb-0'>{errors[FormFields.convenio].message}</p>}
                </div>
                <div
                  className={`col-12 pb-3 px-0 px-md-3 ${errors[FormFields.matricula] &&
                    'input-error'}`}
                >
                  <label htmlFor={FormFields.matricula} className='fs-14 lh-18 d-block'>
                    Beneficio/Matrícula
                  </label>
                  <input
                    className='w-100'
                    ref={register({
                      required: 'Digite uma matrícula válida',
                    })}
                    name={FormFields.matricula}
                    id={FormFields.matricula}
                    type='text'
                    placeholder='Digite seu benefício ou matrícula, apenas números'
                  />
                  {errors[FormFields.matricula] && (
                    <p className='fs-12 text-right mb-0'>
                      {errors[FormFields.matricula].message}
                    </p>
                  )}
                </div>
                <div className={`col-12 d-flex flex-column px-0 px-md-3 pb-md-3 ${errors[FormFields.dataNascimento] &&
                  'input-error'}`}
                >
                  <label htmlFor={FormFields.dataNascimento} className='fs-14 lh-18'>
                    Data de Nascimento
                  </label>
                  <input
                    ref={register({
                      required: 'Digite uma data válida',
                      validate: {
                        isDate: (value: string) => Validations.dateValid(value) || 'Data Inválida',
                      },
                    })}
                    name={FormFields.dataNascimento}
                    id={FormFields.dataNascimento}
                    type='tel'
                    placeholder='dd/mm/aaaa'
                    maxLength={10}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('dataNascimento', Masks.MaskDATE(event.currentTarget.value))}
                  />
                  {errors[FormFields.dataNascimento] && (
                    <p className='fs-12 text-red--base mb-0 text-right'>
                      {errors[FormFields.dataNascimento].message}
                    </p>
                  )}
                </div>
                <div className={`col-12 pb-3 px-0 px-md-3 ${errors[FormFields.product] && 'input-error'}`}>
                  <label htmlFor={FormFields.product} className='fs-14 lh-18 d-block text-grayscale--100'>Produto</label>
                  <span className='select'>
                    <select
                      className={hasOptionSelected(product)}
                      name={FormFields.product}
                      id={FormFields.product}
                      ref={register({
                        required: 'Selecione o produto',
                      })}
                      onChange={(event: ChangeEvent<HTMLSelectElement>) => setProduct(event.currentTarget.value)}
                    >
                      <option disabled selected value=''>Selecione o produto</option>
                      {products.map((item: SelectOptionsType) => (
                        <option value={item.value} key={item.name}>{item.name}</option>
                      ),
                    )}
                    </select>
                  </span>
                  {errors[FormFields.product] && <p className='fs-12 text-right mb-0'>{errors[FormFields.product].message}</p>}
                </div>
                <div
                  className={`col-12 d-flex flex-column px-0 px-md-3 pb-md-3 ${errors[FormFields.valor] &&
                    'input-error'}`}
                >
                  <label htmlFor={FormFields.valor} className='fs-14 lh-18'>
                    Valor
                  </label>
                  <input
                    ref={register({
                      required: 'Digite uma data válida',
                    })}
                    name={FormFields.valor}
                    id={FormFields.valor}
                    type='text'
                    placeholder='Digite apenas números'
                    value={`R$ ${floatToCurrency(inputValue)}`}
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => changeInput(evt)}
                  />
                  {errors[FormFields.valor] && (
                    <p className='fs-12 text-red--base mb-0 text-right'>
                      {errors[FormFields.valor].message}
                    </p>
                  )}
                </div>
                <div className='col-12 d-flex flex-column pt-2 pt-md-0 px-0 px-md-3 mt-md-n2 mt-lg-n1 mt-xl-2'>
                  <Checkbox className='m-0'>
                    <input
                      name='aceite'
                      id='aceite'
                      type='checkbox'
                      className='form-input-check'
                      onChange={() => setAccept(!accept)}
                    />
                    <label className='form-label-check text-grayscale--500 fs-14 lh-17 fw-400' htmlFor='aceite'>
                      Estou de acordo com a
                      <a
                        className='fw-700 text-grayscale--500'
                        title='Acessar Política de Privacidade do Inter'
                        href={urlPrivacidade}
                        target='_blank' rel='noreferrer'
                        onClick={() => {
                          sendDatalayerEvents({
                            section: 'dobra_1',
                            section_name: 'Empréstimo Consignado Inter',
                            element_action: 'submit',
                            element_name: 'Receber Proposta',
                            redirect_url: urlPrivacidade,
                          })
                        }}
                      > Política de Privacidade
                      </a> do Inter.
                    </label>
                  </Checkbox>
                </div>
                <div className='col-12 text-center mt-2 pt-lg-2'>
                  <button
                    type='submit'
                    title='Receber Proposta'
                    disabled={!accept || status === formStatus.loading}
                    className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none'
                  >
                    {status === formStatus.loading
                      ? 'Enviando...'
                      : 'Receber Proposta'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  )
}

export default ParceiroConsignadoForm
