import React from 'react'
import * as S from './style'
import Img from 'gatsby-image'
import pageQuery from './pageQuery'

import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'
import ParceiroConsignadoForm from './ParceiroConsignadoForm/_index'

import LogoInterNasdaqListed from './assets/images/logo-inter-nasdaq-listed.png'

const ParceiroConsignado = () => {
  const data = pageQuery()

  return (
    <Layout pageContext={pageContext}>
      <S.HeaderLogo>
        <img src={LogoInterNasdaqListed} alt='logo inter nasdaq listed.' />
      </S.HeaderLogo>
      <S.Section className='py-5'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-12 col-lg-6'>
              <h1 className='fs-32 lh-40 fs-md-72 lh-md-90 fw-600 text-white text-center text-lg-left mb-3 mb-md-4'>
                Empréstimo <span className='d-lg-block'>Consignado</span> Inter
              </h1>
              <p className='fs-16 lh-20 fs-md-20 lh-md-25 fw-400 text-white text-center text-lg-left font-sora mb-5 mb-lg-0'>
                Crédito exclusivo para servidores públicos, aposentados e pensionistas do INSS. Simples, seguro,
                com parcelas a partir de R$24,70 e parcelamento em até 84 meses.
              </p>
              <Img
                fluid={data.bannerEmprestimoConsignadoInter.fluid}
                alt='Homem segurando cartão de crédito Inter'
                className='d-none d-lg-block'
              />
            </div>
            <div className='col-12 col-lg-6'>
              <ParceiroConsignadoForm />
            </div>
          </div>
        </div>
      </S.Section>
    </Layout>
  )
}

export default ParceiroConsignado
